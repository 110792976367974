/* eslint-disable */
import React, { useState } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Icon from '@mdi/react'
import { mdiFormatQuoteClose, mdiFormatQuoteOpen } from '@mdi/js'
import {
	Box,
	Container,
	Divider,
	Grid,
	makeStyles,
	Typography
} from '@material-ui/core'
import MyTheme from '../utils/myTheme'

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4)
	},
	info: {
		fontSize: '1.2rem',
		fontWeight: 100,
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4)
	},
	info2: {
		fontSize: '1rem',
		fontWeight: 100,
		marginBottom: theme.spacing(2)
	},
	sol: {
		width: 150,
		margin: theme.spacing(5)
	},
	windset: {
		color: theme.palette.secondary.main,
		textDecoration: 'underline'
	},
	dana: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(4)
	},
	divider: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(0.25),
		backgroundColor: theme.palette.primary.main
	}
}))

const Dana = () => {
	const classes = useStyles()
	const theme = MyTheme
	return (
		<StaticQuery
			query={graphql`
				query {
					dana: file(name: { eq: "Dana" }) {
						childImageSharp {
							fluid(quality: 90, maxWidth: 1920) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			`}
			render={data => {
				return (
					<>
						{/* <Container component='main' className={classes.main} maxWidth='md'> */}
						<Typography
							variant='h2'
							align='center'
							color='textPrimary'
							className={classes.container}
							gutterBottom
						>
							Chef Dana Reinhardt
						</Typography>

						<Divider variant='middle' className={classes.divider} />

						<Typography
							variant='subtitle1'
							color='textPrimary'
							align='center'
							gutterBottom
							className={classes.info}
						>
							<p>
								After many culinary trips to Italy, Dana is realizing a life
								long dream of hosting cooking classes and culinary adventures in
								Lazio, Italy.{' '}
							</p>
							<p>
								Dana began bringing groups to Italy in 2014. Dana provides rare,
								off the beaten path food and wine experiences from her many
								friendships with chefs, wine makers and Artisan producers
								throughout the region. With a private driver you will embark on
								day trips to local markets, restaurants, historic Etruscan
								villages and top notch wineries. Each day you will participate
								in “hands-on” cooking classes with Dana and feast on the fruits
								of your labours. Dana brings her extensive professional cooking
								expertise and her fun loving style of teaching to each group she
								hosts.
							</p>
							<p>
								Each year, Dana mentors a female chef by bringing them with her
								to learn the secrets of Italian cuisine. Private, custom tours
								are also available.
							</p>
						</Typography>

						<Grid container spacing={1} className={classes.container}>
							<Grid container item xs={12}>
								<Grid item xs={12} md={6}>
									<Img
										fluid={data.dana.childImageSharp.fluid}
										className={classes.dana}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Typography
										variant='body1'
										color='textPrimary'
										gutterBottom
										className={classes.info2}
									>
										Dana began her career in the hospitality industry at the age
										of 12, spinning cotton candy and selling corn dogs at the{' '}
										PNE. Her father was in the wine importing business for many
										years and instilled a love of wine, food and travel.
									</Typography>

									<Typography
										variant='body1'
										color='textPrimary'
										gutterBottom
										className={classes.info2}
									>
										Dana’s culinary fate was sealed when, after managing several
										flagship Vancouver restaurants in her early twenties, she
										followed her passion into the kitchen, completing her formal
										chef’s education at Dubrulle Culinary School and launching
										her extremely successful inaugural venture, Cellar Door
										Restaurant at Sumac Ridge Winery in the Okanagan.
									</Typography>

									<Typography
										variant='body1'
										color='textPrimary'
										gutterBottom
										className={classes.info2}
									>
										To deepen her craft further, she completed an apprenticeship
										at the storied River Cafe in London, England, an experience
										that continues to fuel her creatively to this day. Inspired
										and emboldened by what she learned there, she returned to
										Vancouver and launched one of the most innovative and
										awarded small plates restaurants in Vancouver’s culinary
										history, CRU.
									</Typography>

									<Typography
										variant='body1'
										color='textPrimary'
										gutterBottom
										className={classes.info2}
									>
										Dana is the principal at SOL Kitchen, a food and hospitality
										consulting company, where she can take full advantage of her
										experience on both sides of the restaurant for a diverse
										range of clients, from fast-casual restaurants like
										Noodlebox to being the representative in Canada for New
										Zealand, for whom she served as Chef Ambassador to local
										food producers like Windset Farms{' '}
										<em>
											(see more of Dana’s recipes on{' '}
											<a
												href='http://www.windsetfarms.com/'
												target='_blank'
												className={classes.windset}
											>
												Windset Farms
											</a>
											)
										</em>
										.
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Typography
							variant='subtitle1'
							color='primary'
							align='center'
							gutterBottom
							className={classes.info}
						>
							<Icon
								path={mdiFormatQuoteOpen}
								title='quote'
								size={1}
								color={theme.palette.primary.main}
							/>
							<em>
								The cooking school experience exceeded my expectations and has
								given us such pleasure and awakened our need to do more culinary
								adventures. We may never take our aprons off agains! Dana made
								every cooking and learning moment interesting and exciting.
								Simply, one of the greatest experiences we have had in our
								lives!
							</em>
							<Icon
								path={mdiFormatQuoteClose}
								title='quote'
								size={1}
								color={theme.palette.primary.main}
							/>
							<small style={{ color: theme.palette.secondary.main }}>
								{' '}
								– John Johnson, Maple Ridge BC
							</small>
						</Typography>
					</>
				)
			}}
		/>
	)
}

export default Dana
