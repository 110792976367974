/* eslint-disable */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import {
	AppBar,
	Box,
	Container,
	Grid,
	Hidden,
	makeStyles,
	Tabs,
	Tab,
	Typography,
	useScrollTrigger
} from '@material-ui/core'
import MyTheme from '../utils/myTheme'

import About from './about'
import Dana from './dana'
import Contact from './contact'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<Typography
			component='div'
			role='tabpanel'
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
}

function a11yProps(index) {
	return {
		id: `wrapped-tab-${index}`,
		'aria-controls': `wrapped-tabpanel-${index}`
	}
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	tabTitle: {
		fontSize: '1rem',
		color: 'white',
		textTransform: 'capitalize',
		[theme.breakpoints.down('sm')]: {
			fontSize: '.75rem'
		}
	},
	tabs: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	main: {
		paddingTop: theme.spacing(5)
	}
}))

const TabsPage = props => {
	const classes = useStyles()
	const theme = MyTheme
	const [value, setValue] = useState('one')

	const handleChange = (event, newValue) => {
		let element
		setValue(newValue)
		if (newValue === 'home') {
			element = document.getElementById('carousel')
			element.scrollIntoView({ behavior: 'smooth', block: 'start' })
			// document.getElementById('logo').classList.remove('hide')
			setValue('one')
		} else {
			// document.getElementById('logo').classList.add('hide')
			element = document.getElementById('info')
			element.scrollIntoView({ behavior: 'smooth', block: 'start' })
		}
	}

	return (
		<>
			<div className={classes.root} id='info'>
				<AppBar position='fixed' color='secondary'>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label='wrapped label tabs example'
						centered
						variant='fullWidth'
						className={classes.tabs}
					>
						<Tab
							value='home'
							label='home'
							wrapped
							{...a11yProps('home')}
							className={classes.tabTitle}
						/>
						<Tab
							value='one'
							label='Booking Info'
							wrapped
							{...a11yProps('one')}
							className={classes.tabTitle}
						/>
						<Tab
							value='two'
							label='Chef Dana'
							{...a11yProps('two')}
							className={classes.tabTitle}
						/>
						<Tab
							value='three'
							label='Contact'
							{...a11yProps('three')}
							className={classes.tabTitle}
						/>
					</Tabs>
				</AppBar>
				<Container component='main' className={classes.main} maxWidth='md'>
					<TabPanel value={value} index='one'>
						<About />
					</TabPanel>
					<TabPanel value={value} index='two'>
						<Dana />
					</TabPanel>
					<TabPanel value={value} index='three'>
						<Contact />
					</TabPanel>
				</Container>
			</div>
		</>
	)
}

export default TabsPage
