/* eslint-disable */
import React, { useRef, createRef } from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import Carousel from 're-carousel'
import {
	Button,
	Container,
	Grid,
	Grow,
	makeStyles,
	Slide,
	Typography,
	useScrollTrigger,
} from '@material-ui/core'
import { grey, indigo } from '@material-ui/core/colors'

import MyTheme from '../utils/myTheme'

import SEO from '../components/SEO'
import TabsPage from '../components/tabs'
import About from '../components/about'
import Dana from '../components/dana'
import Footer from '../components/footer'

const useStyles = makeStyles((theme) => ({
	imageDiv: {
		paddingTop: 64,
		height: '65vh',
		// [theme.breakpoints.down('xs')]: {
		// 	height: '55vh'
		// },
		[theme.breakpoints.down('sm')]: {
			height: '45vh',
			// height: '55vh',
		},
		marginTop: theme.spacing(-2),
	},
	backgroundImage: {
		marginTop: theme.spacing(-2),
		width: '100%',
		height: '100%',
		backgroundRepeat: 'repeat-y',
		backgroundSize: 'cover',
	},
	backgroundGrid: {
		// top: '55vh',
		minHeight: '65vh',
		// [theme.breakpoints.down('sm')]: {
		// 	minHeight: '55vh',
		// 	top: '55vh'
		// },
		[theme.breakpoints.down('sm')]: {
			minHeight: '35vh',
			// top: '17vh'
		},
	},
	logo: {
		// backgroundColor: grey[400],
		height: 250,
		width: 250,
		[theme.breakpoints.down('sm')]: {
			height: 150,
			width: 150,
			top: -175,
			// top: -75,
			// top: '58vh'
		},
		borderRadius: '50%',
		// padding: theme.spacing(1),
		position: 'relative',
		top: -120,
		// position: 'absolute',
		// top: '65vh',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 1000,
	},
	mainDiv: {
		height: '35vh',
		[theme.breakpoints.down('sm')]: {
			height: '55vh',
			paddingTop: theme.spacing(20),
		},
		backgroundColor: theme.palette.primary.main,
		marginTop: theme.spacing(-2.5),
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(4),
	},
	button: {
		margin: theme.spacing(2),
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		color: 'white',
	},
	heading: {
		color: 'white',
		fontWeight: 100,
		marginTop: theme.spacing(-30),
		// marginTop: theme.spacing(4)
	},
	subHeading: {
		color: 'white',
		fontWeight: 100,
		marginBottom: theme.spacing(4),
	},
}))

export default ({ data }) => {
	const social = data.social.edges[0].node.siteMetadata.social
	const author = data.author.edges[0].node.siteMetadata.author
	const classes = useStyles()
	const theme = MyTheme
	const trigger = useScrollTrigger()

	// const scrollToAbout = () => {
	// 	var element = document.getElementById('info')
	// 	element.scrollIntoView({ behavior: 'smooth', block: 'start' })
	// }
	// const myRef = createRef()
	// const getOffset = () => {
	// 	console.log(this.myRef.current.offsetTop)
	// }

	// const onScroll = () => {
	// 	console.log(exampleRef.current.offsetTop)
	// }

	return (
		<div>
			<SEO title='Cook Italy' />
			<Slide direction='down' in={true} mountOnEnter unmountOnExit>
				<div className={classes.imageDiv} id='carousel'>
					<Carousel loop auto duration={2000}>
						<BackgroundImage
							Tag='section'
							className={classes.backgroundImage}
							fluid={data.five.childImageSharp.fluid}
						>
							<div className={classes.backgroundGrid} />
						</BackgroundImage>
						<BackgroundImage
							Tag='section'
							className={classes.backgroundImage}
							fluid={data.one.childImageSharp.fluid}
						>
							<div className={classes.backgroundGrid} />
						</BackgroundImage>
						<BackgroundImage
							Tag='section'
							className={classes.backgroundImage}
							fluid={data.four.childImageSharp.fluid}
						>
							<div className={classes.backgroundGrid} />
						</BackgroundImage>
						<BackgroundImage
							Tag='section'
							className={classes.backgroundImage}
							fluid={data.six.childImageSharp.fluid}
						>
							<div className={classes.backgroundGrid} />
						</BackgroundImage>
					</Carousel>
				</div>
			</Slide>

			{/* <div className={classes.logo} id='logo'>
				<Img
					fluid={data.logo.childImageSharp.fluid}
					className={classes.media}
				/>
			</div> */}

			<div className={classes.mainDiv}>
				<div className={classes.logo} id='logo'>
					<Img
						fluid={data.logo.childImageSharp.fluid}
						className={classes.media}
					/>
				</div>
				<Grow in={true} timeout={800}>
					<>
						<Typography
							variant='h3'
							className={classes.heading}
							align='center'
							gutterBottom
						>
							September 16 to September 21, 2020
						</Typography>
						<Typography
							variant='h4'
							align='center'
							gutterBottom
							className={classes.subHeading}
						>
							6 day guided culinary tour.
						</Typography>
					</>
				</Grow>
			</div>

			<div>
				<TabsPage />
			</div>

			<Footer
				social={social}
				author={author}
				fluid={data.sol.childImageSharp.fluid}
			/>
		</div>
	)
}

export const query = graphql`
	query {
		one: file(name: { eq: "1" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		six: file(name: { eq: "6" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		three: file(name: { eq: "3" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		four: file(name: { eq: "4" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		five: file(name: { eq: "5" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		logo: file(name: { eq: "cookItalyLogo" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		social: allSite {
			edges {
				node {
					siteMetadata {
						social {
							twitter
							facebook
							instagram
						}
					}
				}
			}
		}
		author: allSite {
			edges {
				node {
					siteMetadata {
						author
					}
				}
			}
		}
		sol: file(name: { eq: "sol-kitchen" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 181) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`
