/* eslint-disable */
import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Icon from '@mdi/react'
import {
	mdiFacebookBox,
	mdiInstagram,
	mdiLinkedinBox,
	mdiTwitterBox
} from '@mdi/js'
import {
	Button,
	Divider,
	Grid,
	makeStyles,
	Typography
} from '@material-ui/core'

import { grey } from '@material-ui/core/colors'
import MyTheme from '../utils/myTheme'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: grey[300],
		padding: theme.spacing(5),
		marginLeft: theme.spacing(-1),
		marginRight: theme.spacing(-1)
	},
	type: {
		fontWeight: 'fontWeightLight',
		fontSize: 12,
		color: theme.palette.primary.main
	},
	followUs: {
		marginTop: theme.spacing(-3),
		fontWeight: 'fontWeightLight',
		fontSize: 12,
		color: theme.palette.primary.main
	},
	button: {
		fontWeight: 'fontWeightLight',
		fontSize: 12,
		color: theme.palette.primary.main,
		textTransform: 'capitalize'
	},
	link: {
		textDecoration: 'none'
	},
	sol: {
		width: 150,
		margin: theme.spacing(5)
	},
	divider: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(0.25),
		backgroundColor: theme.palette.primary.main
	}
}))

const Footer = ({ author, social, fluid }) => {
	const classes = useStyles()
	const theme = MyTheme

	return (
		<>
			<Divider variant='middle' className={classes.divider} />
			<center>
				<div className={classes.sol}>
					<a
						href='https://sol-kitchen.net/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Img fluid={fluid} />
					</a>
				</div>
			</center>

			<footer className={classes.root}>
				<Grid container spacing={4}>
					<Grid item xs={6}>
						<Typography variant='body1' className={classes.followUs}>
							Follow Us
						</Typography>
						<Typography variant='body1' className={classes.type}>
							{social.facebook && (
								<a
									href={`https://facebook.com/${social.facebook}`}
									target='_blank'
									rel='noreferrer noopener'
								>
									<Icon
										path={mdiFacebookBox}
										title='Facebook'
										size={1.25}
										color={theme.palette.primary.main}
									/>
								</a>
							)}
							{social.twitter && (
								<a
									href={`https://twitter.com/${social.twitter}`}
									target='_blank'
									rel='noreferrer noopener'
								>
									<Icon
										path={mdiTwitterBox}
										title='Twitter'
										size={1.25}
										color={theme.palette.primary.main}
									/>
								</a>
							)}
							{social.linkedin && (
								<a
									href={`https://linkedin.com/in/${social.linkedin}`}
									target='_blank'
									rel='noreferrer noopener'
								>
									<Icon
										path={mdiLinkedinBox}
										title='Linkedin'
										size={1.25}
										color={theme.palette.primary.main}
									/>
								</a>
							)}
							{social.instagram && (
								<a
									href={`https://instagram.com/${social.instagram}`}
									target='_blank'
									rel='noreferrer noopener'
								>
									<Icon
										path={mdiInstagram}
										title='Instagram'
										size={1.25}
										color={theme.palette.primary.main}
									/>
								</a>
							)}
						</Typography>
					</Grid>

					<Grid item xs={6} align='right'>
						<a
							href='https://sol-kitchen.net/'
							target='_blank'
							rel='noopener noreferrer'
						>
							<Typography variant='body1' className={classes.type}>
								{author} © {new Date().getFullYear()}
							</Typography>
						</a>
					</Grid>
				</Grid>
			</footer>
		</>
	)
}

export default Footer
