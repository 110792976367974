/* eslint-disable */
import React, { useState } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import {
	Box,
	Container,
	Divider,
	Grid,
	makeStyles,
	Typography
} from '@material-ui/core'
import MyTheme from '../utils/myTheme'

import Photos from './photos'

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4)
	},
	info: {
		fontSize: '1.2rem',
		fontWeight: 100,
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4)
	},
	sol: {
		width: 150,
		margin: theme.spacing(5)
	},
	divider: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(0.25),
		backgroundColor: theme.palette.primary.main
	}
}))

const About = () => {
	const classes = useStyles()
	const theme = MyTheme
	return (
		<>
			<Typography
				variant='h2'
				align='center'
				color='textPrimary'
				className={classes.container}
				gutterBottom
			>
				Now booking for 2020
			</Typography>

			<Divider variant='middle' className={classes.divider} />

			<Photos />

			<Typography variant='h3' align='center' color='textPrimary' gutterBottom>
				September 16 to September 21, 2020
			</Typography>
			<Typography variant='h4' align='center' color='textPrimary' gutterBottom>
				TOUR ONLY – $4,199.00
			</Typography>

			<Typography
				variant='subtitle1'
				color='textPrimary'
				align='center'
				gutterBottom
				className={classes.info}
			>
				5 nights (6 days) accommodation based on double occupancy.
				<br></br> 6 day guided culinary tour.<br></br>All meals and wine
				included.<br></br>
				<b>Important Note:</b> Unfortunately unable to accommodate gluten free
				allergies or vegetarian diets.
			</Typography>

			<Typography
				variant='subtitle1'
				color='textPrimary'
				align='center'
				gutterBottom
				className={classes.info}
			>
				4 off-site day trips, including transportation: Sergio Mottura Winery
				with spectacular wine and food pairing, 5 course dinner, tour of
				Medieval Tuscania, village market with lunch in a wonderful local
				trattoria, stuffed pasta making with local nonnas and dinner.
			</Typography>

			<Typography
				variant='subtitle1'
				color='textPrimary'
				align='center'
				gutterBottom
				className={classes.info}
			>
				<b>Not included:</b> Transportation to/from the farm, tips (guides,
				servers, drivers etc.),<br></br>
				travel insurance, items of a personal nature.
			</Typography>
		</>
	)
}

export default About
