/* eslint-disable */
import React, { useState } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'
import {
	Box,
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	GridList,
	GridListTile,
	makeStyles,
	Typography,
	useMediaQuery
} from '@material-ui/core'
import MyTheme from '../utils/myTheme'

const useStyles = makeStyles(theme => ({
	container: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4)
	}
}))

const Photos = () => {
	const classes = useStyles()
	const theme = MyTheme
	const [open, setOpen] = useState(false)
	const [num, setNum] = useState(0)
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	const handleClickOpen = number => {
		setNum(number)
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
		setNum(0)
	}

	return (
		<StaticQuery
			query={graphql`
				query {
					album: allFile(
						filter: {
							extension: { eq: "jpeg" }
							sourceInstanceName: { eq: "images" }
						}
					) {
						edges {
							node {
								id
								childImageSharp {
									fluid(quality: 90, maxWidth: 1920) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
				}
			`}
			render={data => {
				const album = data.album.edges
				return (
					<>
						<Dialog
							fullWidth
							maxWidth='lg'
							open={open}
							onClose={handleClose}
							aria-labelledby='responsive-dialog-title'
						>
							<DialogActions>
								<Button autoFocus onClick={handleClose} color='primary'>
									<Icon
										path={mdiClose}
										title='close'
										size={1.5}
										color={theme.palette.primary.main}
									/>
								</Button>
							</DialogActions>
							<DialogContent>
								<Img fluid={data.album.edges[num].node.childImageSharp.fluid} />
							</DialogContent>
						</Dialog>

						<Grid container spacing={1} className={classes.container}>
							<Grid container item xs={12} spacing={1}>
								{album.map((photo, index) => {
									return (
										<Grid
											key={index}
											item
											xs={12}
											sm={6}
											md={4}
											onClick={() => handleClickOpen(index)}
											style={{ cursor: 'pointer' }}
										>
											<Img fluid={photo.node.childImageSharp.fluid} />
										</Grid>
									)
								})}
							</Grid>
						</Grid>
					</>
				)
			}}
		/>
	)
}

export default Photos
